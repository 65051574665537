import React from 'react';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';

import UploadcareImage from '@uploadcare/nextjs-loader';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import EmbedVideo from './embedVideo';

const VideoPreviewContainer = styled(Box)(() => ({
  position: 'relative',
  '&>div': {
    borderRadius: '8px',
    overflow: 'clip',
  },
  '&>div>video': {
    objectFit: 'contain',
  },
}));

function ContributionMedia(props) {
  const {
    media,
    mode,
    allowNonUniformImageHeights = false,
    borderRadius = '8px',
    previewOnly = false,
    fitWidth = true,
    fitHeight = false,
    width,
    height,
  } = props;
  const { url, thumbnailUrl } = media;
  const isVertical = (media?.height && media?.width && (media.height > media.width));
  const verticalBlurOverlay = (zoomWidth = 100, zoomHeight = 100) => (
    isVertical
      ? `-/blur/60/-/gamma/50/-/overlay/self/${zoomWidth}px${zoomHeight}p/center/`
      : ''
  );
  const altText = media?.inference?.altText ?? '';
  if (mode === 'preview') {
    switch (media?.type) {
      case 'video':
      case 'videoEmbed':
        return (
          <VideoPreviewContainer>
            <EmbedVideo url={url} width='250px' height='250px' />
          </VideoPreviewContainer>
        );
      default:
        return <img alt={altText} src={`${url}-/preview/250x250/`} style={{ borderRadius }} />;
    }
  }
  if (mode === 'thumbnail') {
    switch (media?.type) {
      case 'video':
      case 'videoEmbed':
        return (
          <VideoPreviewContainer fontSize='8px'>
            <EmbedVideo url={url} width='140px' height='110px' previewOnly light />
          </VideoPreviewContainer>
        );
      default:
        return <img alt='' src={`${url}-/scale_crop/280x220/smart_faces_objects/`} style={{ borderRadius, width: '140px', height: '110px' }} />;
    }
  }
  if (mode === 'hero') {
    const heroMaxWidth = 840;
    const heroMaxHeight = 500;
    const verticalVideoProps = isVertical ? { width: heroMaxWidth, height: heroMaxHeight } : {};
    switch (media?.type) {
      case 'video':
        return (
          <EmbedVideo
            url={url}
            thumbnailUrl={`${thumbnailUrl}-/scale_crop/840x500/smart_faces_objects/${verticalBlurOverlay()}`}
            previewOnly={previewOnly}
            {...verticalVideoProps}
          />
        );
      case 'videoEmbed':
        return (
          <EmbedVideo
            url={url}
            thumbnailUrl={thumbnailUrl}
            light
            playing
            previewOnly={previewOnly}
            {...verticalVideoProps}
          />
        );
      default:
        return (
          <UploadcareImage
            src={url}
            quality='80'
            width={heroMaxWidth}
            height={heroMaxHeight}
            layout='fill'
            priority
            alt={altText}
          />
        );
    }
  }
  switch (media?.type) {
    case 'video':
      return (
        <EmbedVideo
          url={url}
          thumbnailUrl={`${thumbnailUrl}-/scale_crop/${width}x${height}/smart_faces_objects/${verticalBlurOverlay(100, 150)}`}
          sx={{ position: 'relative', fontSize: '12px' }}
          previewOnly={previewOnly}
          onClick={stopEvent}
          width={!fitWidth && fitHeight && width}
          height={!fitHeight && fitWidth && height}
          freeAspect={fitWidth && fitHeight}
        />
      );
    case 'videoEmbed':
      return (
        <EmbedVideo
          url={url}
          sx={{ position: 'relative', fontSize: '12px' }}
          onClick={stopEvent}
          previewOnly={previewOnly}
          light
          playing
          width={!fitWidth && fitHeight && width}
          height={!fitHeight && fitWidth && height}
          freeAspect={fitWidth && fitHeight}
        />
      );
    default:
      return (
        allowNonUniformImageHeights
          ? (
            <img
              alt={altText}
              src={`${url}/-/resize/${width || 360}x/`}
              style={{ objectFit: 'cover', maxWidth: '100%' }}
            />
          )
          : (
            <img
              alt={altText}
              src={`${url}-/scale_crop/${width || 360}x${height || 200}/smart_faces_objects/`}
              height={height || 200}
              width={width || 360}
              style={{
                borderRadius,
                width: fitWidth ? '100%' : 'auto',
                height: fitHeight ? '100%' : 'auto',
                objectFit: 'cover',
              }}
            />
          )
      );
  }
}

export default ContributionMedia;

import React from 'react';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import CircleIcon from '@tmap/mmm-style-guide/src/CircleIcon';
import imageUrlBuilder from '@sanity/image-url';
import { badges } from '@tmap/mmm-style-guide/src/badges';

import client from '../../client';

const builder = imageUrlBuilder(client);

const StyledPicture = styled('picture', {
  shouldForwardProp: (p) => p !== 'cutout',
})(({ cutout, size }) => {
  const cutoutSize = 2 + (size / 2);
  const cutoutLeft = -(size / 8) - 1;
  const cutoutTop = -1;
  // box the whole area first so evenodd will subtract within it
  // move to the top of the cutout circle
  // then make 2 arcs to take the bite out
  return {
    width: `${size}px`,
    height: `${size}px`,
    ...(cutout ? {
      clipPath: `path( \
      evenodd, \
      '\
        M 0,0 h ${size} v ${size} h ${-size} v ${-size} \
        M ${cutoutLeft + cutoutSize / 2},${cutoutTop} \
        a ${cutoutSize / 2} ${cutoutSize / 2} 0 0 1 0,${cutoutSize} \
        a ${cutoutSize / 2} ${cutoutSize / 2} 0 0 1 0,${-cutoutSize} \
      '
    )`,
    } : {}),
  };
});

const RoundedBox = styled(Box)(() => ({
  borderRadius: '100%',
}));

const BadgeIcon = styled(CircleIcon, {
  shouldForwardProp: (p) => !['colors', 'cutout', 'size'].includes(p),
})(({
  theme,
  colors,
  cutout,
  size,
}) => ({
  fontSize: `${size / 2}px`,
  color: colors(theme).iconFg,
  backgroundColor: colors(theme).iconBg,
  ...(!cutout ? { border: `1px solid ${theme.palette.common.white}` } : {}),
}));

const BadgePortrait = styled(CircleIcon, {
  shouldForwardProp: (p) => !['colors', 'size'].includes(p),
})(({ theme, colors, size }) => ({
  fontSize: `${size}px`,
  color: colors(theme).iconFg,
  backgroundColor: colors(theme).iconBg,
}));

export function Portrait(props) {
  const {
    author = {},
    image = author.profileImage,
    size = 32,
    hideBadgeIcon = false,
    cutout = false,
  } = props;
  const { badges: authorBadges = [] } = author;
  const primaryBadge = badges[authorBadges.find((b) => badges[b] && !badges[b].disabled)];

  if (image?.asset || image?.assetId) {
    return (
      <Box display='flex' alignItems='center' position='relative'>
        <StyledPicture
          size={size}
          cutout={cutout && !hideBadgeIcon && primaryBadge}
        >
          <source srcSet={builder.image(image).format('webp').width(104).height(104)
            .url()}
          />
          <RoundedBox
            component='img'
            src={builder.image(image).width(104).height(104).url()}
            width={size}
            height={size}
            alt={(image || {}).alt}
          />
        </StyledPicture>
        {!hideBadgeIcon && primaryBadge && (
          <Box
            position='absolute'
            top='0px'
            left={`-${size / 8}px`}
            display='flex'
            alignItems='start'
          >
            <BadgeIcon
              Icon={primaryBadge.icon}
              variant='contained'
              colors={primaryBadge.colors}
              cutout={cutout}
              size={size}
            />
          </Box>
        )}
      </Box>
    );
  }
  if (primaryBadge) {
    return (
      <Box display='flex' alignItems='center' position='relative'>
        <BadgePortrait
          Icon={primaryBadge.icon}
          variant='contained'
          colors={primaryBadge.colors}
          size={size}
        />
      </Box>
    );
  }
  return null;
}

import makeThunktankAction from '@tmap/mmm-core/redux/thunktank/makeThunktankAction';
import makeView from '@tmap/mmm-core/redux/views/makeView';
import { types } from '@tmap/mmm-core/entityKey';

import { getClient } from '../lib/apiClient';
import { CONTRIBUTIONS_CACHE, QUESTIONS_CACHE } from './cacheIndex';
import { RECEIVE_CURRENT_PROFILE } from '../reducers/user';
import client from '../client';

export const submitContribution = makeThunktankAction(
  (dispatch) => async (contribution, communityId, user) => {
    let updatedProfile;
    if (user) {
      updatedProfile = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
        '/api/v1/user_profiles/upsert',
        { firstName: user.firstName, lastName: user.lastName },
        { params: { deepMerge: true } },
      )).data;
      if (user.profileImage) {
        const formData = new FormData();
        formData.append('Content-Type', user.profileImage.type);
        formData.append('file', user.profileImage);
        const { profileImage } = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/user_profiles/avatar', formData)).data;
        updatedProfile.profileImage = profileImage;
      }
      dispatch({
        type: RECEIVE_CURRENT_PROFILE,
        profile: updatedProfile,
      });
    }
    const { author, ...otherContributionProps } = contribution;
    const createdContribution = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
      '/api/v1/contributions/create',
      { communityId, ...otherContributionProps, authorId: author.userId },
    )).data;

    return {
      ...createdContribution,
      author: updatedProfile ?? author,
    };
  },
).withStore().withCache(CONTRIBUTIONS_CACHE).withErrors({
  all: 'There was an error submitting this post. Please try again later.',
});

export const updateContribution = makeThunktankAction(async (contribution, communityId) => {
  const { author, ...otherContributionProps } = contribution;
  const updatedContribution = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
    '/api/v1/contributions/update',
    { communityId, ...otherContributionProps, authorId: author.userId },
    { params: { unsetNull: true } },
  )).data;
  return {
    ...updatedContribution,
    author, // maintain author hydration
  };
}).withCache(CONTRIBUTIONS_CACHE);

export const deleteContribution = makeThunktankAction(async (contributionId) => {
  const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/remove', {
    _id: contributionId,
  });
  return {
    _id: contributionId,
    deleted: Boolean(res.status === 204),
  };
}).withPartialCache(CONTRIBUTIONS_CACHE);

export const approveContributionAndAuthor = makeThunktankAction(
  async (contribution, makeContributor = true) => {
    const promises = [
      getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/contributions/${contribution._id}/approve`),
    ];
    if (makeContributor) {
      promises.push(getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/communities/${contribution.communityId}/moderators/upsert`, {
        userId: contribution.authorId,
        role: types.CONTRIBUTOR,
      }));
    }
    await Promise.all(promises);
    return ({
      ...contribution,
      approved: true,
      rejected: false,
    });
  },
).withCache(CONTRIBUTIONS_CACHE);

export const rejectContribution = makeThunktankAction(async (contribution) => {
  await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/contributions/${contribution._id}/reject`);
  // the flag we're setting here is front-end only,
  // just to show a temporary "Rejected" banner in the UI
  return ({
    ...contribution,
    approved: false,
    rejected: true,
  });
}).withCache(CONTRIBUTIONS_CACHE);

export const getPendingContributions = makeView(
  async (userId, filters) => {
    if (!userId) {
      return { docs: [] };
    }
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/findPending', filters);
    return ({
      docs: res.data,
    });
  },
).withCache(CONTRIBUTIONS_CACHE);

export const getPendingContributionBySlug = makeView(async (slug) => ({
  docs: [(await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get(`/api/v1/contributions/url/${slug}/pending`)).data],
})).withCache(CONTRIBUTIONS_CACHE).withErrors({
  all: 'Unable to view contribution at this time.',
});

export async function getContributionBySlug(slug) {
  return getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get(
    `/api/v1/contributions/url/${slug}`,
    { validateStatus: (status) => status < 500 },
  );
}

export const findContributions = makeView(
  async (communityId, contributionId, windowBottom, windowTop, otherFilters = {}) => {
    if (!communityId) {
      return ({ docs: [] });
    }
    const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/find', {
      filters: { communityId, _id: { $ne: contributionId }, ...otherFilters },
      sort: { numberOfComments: -1 },
      skip: windowTop,
      limit: windowBottom,
    });
    return ({
      docs: res.data,
    });
  },
).withCache(CONTRIBUTIONS_CACHE);

export const getContributions = makeView(async (contributionIds) => {
  if (contributionIds.length === 0) return { docs: [] };
  const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/find', {
    filters: { _id: { $in: contributionIds } },
    sort: { createdAt: -1 },
    limit: 0,
  });
  return {
    docs: res.data,
  };
}).withCache(CONTRIBUTIONS_CACHE);

export const getContributionsByAuthor = makeView(async (authorId) => {
  if (!authorId) return { docs: [] };
  const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/find', {
    filters: {
      authorId,
      approved: { $in: [true, false, null, undefined] },
    },
    sort: { approved: 1, createdAt: -1 },
    limit: 0,
  });
  return {
    docs: res.data,
  };
}).withCache(CONTRIBUTIONS_CACHE);

export async function getPinnedContributions(filters) {
  return (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/find', {
    filters: {
      ...filters,
      pinned: true,
    },
    limit: 3,
  })).data;
}

export const getPinnedContributionsView = makeView(async (filters) => {
  const res = await getPinnedContributions(filters);
  return { docs: res };
}).withCache(CONTRIBUTIONS_CACHE);

export const getUploadSignature = makeThunktankAction(async () => (
  (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post('/api/v1/contributions/createSignature')).data
)).withErrors({
  rate_limited: 'Due to high traffic, additional media uploads are restricted. Please try again later.',
  no_connection: 'Media uploads are currently unavailable. Please try again later.',
});

export async function getPaginatedCommunityContributions(limit = 6, offset = 0, sortBy = 'top', filters = {}) {
  const { docs, ...paging } = (await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(
    '/api/v2/contributions/paging',
    {
      limit,
      offset,
      sortBy,
      filters,
    },
  )).data;
  return { docs, paging };
}

export const getPaginatedCommunityContributionsView = makeView(
  getPaginatedCommunityContributions,
).withCache(CONTRIBUTIONS_CACHE);

export const getPaginatedCommunityQuestionsView = makeView(
  getPaginatedCommunityContributions,
).withCache(QUESTIONS_CACHE);

export async function getTopicTotals(communityId) {
  return getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get(`/api/v1/contributions/topic-totals?communityId=${communityId}`);
}

export async function getHashtagTotals(communityId) {
  return getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get(`/api/v1/contributions/hashtag-totals?communityId=${communityId}`);
}

export const getAllTopics = makeView(async (communityId) => {
  const [{ data: topics }, { data: tags }] = await Promise.all([
    getTopicTotals(communityId),
    getHashtagTotals(communityId),
  ]);
  return { docs: [...topics, ...tags] };
});

export const togglePin = makeThunktankAction(async (contribution) => {
  const res = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).post(`/api/v1/contributions/${contribution._id}/togglePin`);
  return {
    ...contribution,
    pinned: res.data.pinned,
  };
}).withCache(CONTRIBUTIONS_CACHE);

export const getContentTypeConfig = makeView(async (contributionType = 'default') => {
  const result = await client.fetch('*[_type == \'ugcTypes\' && type == $contributionType][0]', { contributionType });
  return { docs: result };
});

export const getRecommendedContent = makeView(async () => {
  const result = await getClient(process.env.NEXT_PUBLIC_API_DOMAIN).get('/api/v1/contributions/recommended');
  return { docs: result.data };
});

import React from 'react';
import ReactPlayer from 'react-player/lazy';

import { Box } from '@tmap/mmm-style-guide/src/Box';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { PlayButton } from '@tmap/mmm-style-guide/src/Icon';
import { useTheme } from '@tmap/mmm-style-guide/src/Theming';

const VideoOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  fontSize: 'inherit',
}));

const PlayIcon = styled(PlayButton)(() => ({
  fontSize: '6em',
}));

function BaseVideo(props) {
  const {
    thumbnailUrl,
    width,
    height,
    ...otherProps
  } = props;
  return (
    <ReactPlayer
      controls
      light={thumbnailUrl}
      playing={Boolean(thumbnailUrl)}
      playIcon={<PlayIcon />}
      width={width || '100%'}
      height={height || '100%'}
      {...otherProps}
    />
  );
}

function EmbedVideo(props) {
  const { sx, previewOnly, ...otherProps } = props;
  const {
    width,
    height,
    freeAspect = false,
    style,
  } = props;
  const theme = useTheme();
  if (width || height || freeAspect) {
    return (
      <>
        <BaseVideo
          {...otherProps}
          style={{
            width,
            height,
            backgroundColor: theme.palette.common.black,
            ...style,
          }}
        />
        {previewOnly && <VideoOverlay />}
      </>
    );
  }
  return (
    <Box
      className='video-box'
      sx={{
        paddingTop: '56.25%',
        backgroundColor: theme.palette.common.black,
        ...sx,
      }}
    >
      <BaseVideo
        {...otherProps}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          ...style,
        }}
      />
      {previewOnly && <VideoOverlay />}
    </Box>
  );
}

export default EmbedVideo;
